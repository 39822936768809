export const paths = [
    {
        id: 1,
        name: "header_service",
        to: "services/buying_selling",
    },
    {
        id: 2,
        name: "header_about",
        to: "about-us"
    },
    {
        id: 3,
        name: "header_contact",
        to: "contact-us"
    },
]